import React from "react"
// import { graphql } from "gatsby"
// import styled from "styled-components"
// import Page from "../components/page"
// import Renderer from "../components/renderer"

const TemplateStudent = () => {
  // const { body, frontmatter } = mdx
  // const {
  //   name,
  //   // photo,
  // } = frontmatter
  // return (
  //   <Page title={name}>
  //     <Renderer>{body}</Renderer>
  //   </Page>
  // )
  return <div>Student template</div>
}

export default TemplateStudent

// export const pageQuery = graphql`
//   query PageQuery($id: String) {
//     mdx(id: { eq: $id }) {
//       id
//       body
//       frontmatter {
//         name
//         photo {
//           childImageSharp {
//             fluid(maxWidth: 1000) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `
